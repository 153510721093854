<template>
  <vs-collapse type="margin">
    <vs-collapse-item ref="collapseItem" v-for="(item, i) in tags" :key="i">
      <div slot="header" @click="toggleCollapseItem(item.tag.id, i)">
        <div class="flex justify-between items-center">
          <div class="flex items-center">
            <h6 class="font-bold">{{ item.tag.text }}</h6>
          </div>
          <div class="flex items-center mr-base">
            <p class="font-light text-xs max-w-xs truncate">
              <vs-icon icon-pack="feather" icon="icon-tag" class="mr-1 text-xs"></vs-icon>
              {{ item.count }}
            </p>
          </div>
        </div>
      </div>
      <vx-data-list
        class="vx-data-list-captures"
        v-if="contents[item.tag.id]"
        ref="vxDataList"
        :hoverFlat="true"
        :data="contents[item.tag.id] || []"
        :columns="columns"
        :search="false"
        :config="{
          disablePager: true,
          selectable: false
        }"
        @filter="filter(item.tag.id, i)"
      />
    </vs-collapse-item>
  </vs-collapse>
</template>

<script>
import { mapState } from 'vuex';
import CaptureThumbnail from '@/views/captures/components/CaptureThumbnail';
export default {
  name: 'CapturesTags',
  data() {
    return {
      contents: {},
      columns: [
        {
          component: CaptureThumbnail
        }
      ]
    };
  },
  computed: {
    ...mapState('captures', ['tags'])
  },
  methods: {
    async toggleCollapseItem(id, i) {
      const item = this.$refs.collapseItem[i];
      if (!this.contents[id]) {
        item.toggleContent();
        await this.fetchCapturesByTagId(id, i);
        this.$nextTick(() => {
          item.toggleContent();
        });
      }
    },
    async fetchCapturesByTagId(id, i, { paging } = {}) {
      const list = await this.$store.dispatch('captures/list', {
        dontCommit: true,
        params: {
          'tag.id': id
        },
        paging: paging || { size: 10, page: 1 }
      });
      this.$set(this.contents, id, list);
      return list;
    },
    filter(id, i) {
      const item = this.$refs.collapseItem[i];
      const vxDataList = item.$children.find(item => Boolean(item.$refs.table));
      this.fetchCapturesByTagId(id, i, {
        paging: vxDataList.paging
      });
    }
  },
  async created() {
    await this.$store.dispatch('captures/tags');
  },
  mounted() {}
};
</script>
